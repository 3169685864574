import React from 'react';

import parse from 'html-react-parser';
import { getMessages } from '../../locales';
import careersIconSrc from '../../assets/careers/careers-icon.svg';
import Button from '../common/Button/Button';

const Careers = () => {
  const messages = getMessages();

  return (
    <section className='careers-wrapper'>
      <div className='container'>
        <div className="row no-gutters align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-5">
            <img src={careersIconSrc} alt={messages['career.page.title']} />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-7">
            <h1 className='title-lg'>{messages['career.page.title']}</h1>
            <p>{messages['career.page.subtitle']}</p>
            <Button
              title={messages['career.page.button']}
              buttonStyle='redesigned-primary'
              linkTo='https://www.indeed.com/cmp/Spring-Oaks-Capital,-LLC'
              target='_blank'
            />
            <p className='small'>{parse(messages['career.page.content'])}</p>
          </div>
        </div>
      </div>
    </section>
  )
};

export default Careers;

import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../layout';
import config from '../../data/SiteConfig';
import Careers from '../components/Careers/Careers';

function CareersPage() {
  return (
    <Layout>
      <div className="careers-container">
        <Helmet title={`Careers | ${config.siteTitle}`} />
        <Careers />
      </div>
    </Layout>
  );
}

export default CareersPage;
